import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css'


class Gift extends Component {
    render() {
      return (
          <div id="gift" className="gift-area">
              <div className="container">
                    <div className="col-12">
                      <div className="section-title text-center">
                          <h2>Gift Registry</h2>
                          <p>Your love, laughter, and company on our big day is the greatest gift of all. But for those who wish to contribute, please choose a gift from <a href="https://www.hitchd.com/vivianandjeremy">our honeymoon registry</a>.</p>
                      </div>
                  </div>
              </div>
          </div>
      );
    }
  }

export default Gift;