import React from 'react'

import "./style.css"

const Map = () => {
    return(
    <div id="map">
        <div className="map-container">
            <img className="map" src="/images/map.svg" alt="map" />
        </div>
    </div>
    )
}
export default Map;