import React, { Component } from 'react'
import { toast } from 'react-toastify';
import './style.css'

class Rsvp extends Component {
  state = {
    name: '',
    email: '',
    comes: true,
    plus_one_name: '',
    rsvp: '',
    no_meat: false, no_fish: false,
    comes_friday: true, comes_sunday: true,
    comment: '',
    error: {},
  }

  changeHandler = (e) => {
    const { error } = this.state
    error[e.target.name] = ''

    this.setState({
      [e.target.name]: e.target.value,
      error,
    })
  }


  changeCheckboxHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    })
  }

  submitHandler = (e) => {
    e.preventDefault()

    const {
      name,
      email,
      comes,
      plus_one_name,
      no_meat, no_fish,
      comes_friday, comes_sunday,
      comment,
    } = this.state

    const error = {}
    if (name === '') {
      error.name = 'Please enter your name'
    }
    if (email === '') {
      error.email = 'Please enter your email'
    }

    if (name === '' || email === '') {
      return this.setState({
        error,
      })
    }

    const data = {
      invitee: {
        name,
        email,
        comes,
        plus_one_name,
        no_meat,
        no_fish,
        comes_friday,
        comes_sunday,
        comment,
      }
    }

    const BASE_URL = process.env.NODE_ENV === "production" ? "https://boukru-wedding-api.fly.dev" : "http://localhost:3000"
    fetch(`${BASE_URL}/api/invitees`, {method: "POST", body: JSON.stringify(data), headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }})
      .then(() => toast.success(comes ? "Thank you for your reply!" : "Thank you for your reply! We are sad you cannot make it."))
      .catch(() => toast.error("Oops, something went wrong!"))
  }


  render() {
    const {
      name,
      email,
      comes,
      plus_one_name,
      no_meat,
      // no_fish,
      comes_friday, comes_sunday,
      comment, error,
    } = this.state
    return (
      <div id="rsvp" className="rsvp-area go-rsvp-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
              <div className="rsvp-wrap">
                <div className="col-12">
                  <div className="section-title section-title4 text-center">
                    <h2>RSVP</h2>
                    <p>Please say YES!</p>
                  </div>
                </div>
                <form onSubmit={this.submitHandler}>
                  <div className="contact-form form-style">
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <input type="text" value={name} onChange={this.changeHandler} placeholder="Your Name*" id="fname" name="name" />
                        <p>{error.name ? error.name : ''}</p>
                      </div>
                      <div className="col-12  col-sm-6">
                        <input type="text" placeholder="Your Email*" onChange={this.changeHandler} value={email} id="email" name="email" />
                        <p>{error.email ? error.email : ''}</p>
                      </div>
                      <div className="col-12  col-sm-6">
                        <div className={`type-input-wrapper ${comes ? "active" : "unactive"}`} onClick={() => this.setState({...this.state, comes: true})}>
                          <div className={`type-input-title ${comes ? "active" : "unactive"}`}>Of course I will be there for the wedding!</div>
                        </div>
                      </div>
                      <div className="col-12  col-sm-6">
                        <div className={`type-input-wrapper ${comes ? "unactive" : "active"}`} onClick={() => this.setState({...this.state, comes: false})}>
                          <div className={`type-input-title ${comes ? "unactive" : "active"}`}>Unfortunately, I cannot come.</div>
                        </div>
                      </div>
                      { comes && (
                        <>
                          <div className="col col-sm-12 col-12">
                            <input type="text" onChange={this.changeHandler} value={plus_one_name} placeholder="If you have a plus one, please put his/her name" id="plus_one_name" name="plus_one_name" />
                          </div>
                          <div className="col-12  col-sm-12">
                            <input type="checkbox" checked={no_meat} name="no_meat" id="no_meat" onChange={this.changeCheckboxHandler} style={{marginTop: "16px"}} />
                            <label htmlFor="no_meat" style={{marginTop: "16px", marginLeft: "16px"}}>I would rather a vegetarian option</label>
                          </div>
                          <div className="col-12  col-sm-12">
                            <input type="checkbox" checked={comes_friday} name="comes_friday" id="comes_friday" onChange={this.changeCheckboxHandler} style={{marginTop: "16px"}} />
                            <label htmlFor="comes_friday" style={{marginTop: "16px", marginLeft: "16px"}}>I will be there for the welcome dinner on Friday</label>
                          </div>
                          <div className="col-12  col-sm-12">
                            <input type="checkbox" checked={comes_sunday} name="comes_sunday" id="comes_sunday" onChange={this.changeCheckboxHandler} style={{marginTop: "16px"}} />
                            <label htmlFor="comes_sunday" style={{marginTop: "16px", marginLeft: "16px"}}>I will be there for the brunch on Sunday</label>
                          </div>
                          <div className="col-12 col-sm-12">
                            <textarea className="contact-textarea" value={comment} onChange={this.changeHandler} placeholder="If you have any comment, message or song idea for the party" name="comment" />
                          </div>
                        </>)}
                      { !comes && (
                        <div className="col-12 col-sm-12">
                          <textarea className="contact-textarea" value={comment} onChange={this.changeHandler} placeholder="If you have any comment, do not hesitate! We are sad that you cannot make it" name="comment" />
                        </div>
                        )}
                    </div>
                    <div className="col-12 text-center">
                      <button id="submit" type="submit" className="submit">Send</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Rsvp
