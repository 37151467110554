import React from 'react'
import './style.css'


const Welcome = () =>{
    return (
        <div className="welcome-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="welcome-content">
                            <h2>Welcome to our wedding website</h2>
                            <p>
                                We are looking forward to celebrating our love with you. <br />
                                The countdown to our forever is on! <br />
                                We hope you are as excited as we are!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Welcome;