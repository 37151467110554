import React from 'react'
import Sectiontitle from '../section-title'
import story1 from '../../images/events/1.png'
import story2 from '../../images/events/2.png'
import story3 from '../../images/events/3.png'

import './style.css'

const Location = () => {
    return(

        
    <div id="location" className="service-area section-padding">
        <div className="container">
            <Sectiontitle section={'Details'}/>
            <div className="service-area-menu">
                <div className="Ceremony-wrap">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="ceromony-img">
                                <img src={story1} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="ceromony-content">
                                <h3>Welcome Dinner</h3>
                                <strong>Friday, 01 Sept 2023, 7.00 PM</strong>
                                <p>Let's get together on the evening before we tie the knot.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Ceremony-wrap">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="ceromony-content ceromony-content2">
                            <h3>Wedding Day</h3>
                            <strong>Saturday, 02 Sept 2023, 3.30 PM</strong>
                            <p>Let's start with a short refreshment right before the ceremony. We will continue with cocktails after the ceremony which will be followed by a dinner around 7pm.</p>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="ceromony-img">
                            <img src={story2} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Ceremony-wrap">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="ceromony-img">
                             <img src={story3} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="ceromony-content">
                            <h3>Brunch</h3>
                            <strong>Sunday, 03 Sept 2023, 12.00 PM</strong>
                            <p>After a long day and night of celebration, let's meet for a brunch around the swimming-pool, sharing our first day as husband and wife.</p>
                            <p>If you would like to enjoy the pool, do not forget to bring swimwear and a towel.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
export default Location;